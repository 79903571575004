import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import BalSettings from './BalSettings';
import PageSettings from './PageSettings';
import ManageAttendees from './ManageAttendees';
import ImportAttendees from './ImportAttendees';
import { useUser } from '../../Hooks'
import CreateUser from './CreateUser';
import Clear from './Clear';
import { useNavigate } from 'react-router-dom'

export default function Admin(props) {
  const [value, setValue] = React.useState('ManageAttendees');
  const { user, authenticated } = useUser(true);
  const navigate = useNavigate();
  if (!user || !authenticated) {
    return null;
  }
  
  const logOut = () => {
    localStorage.clear();
    navigate(0);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAdminPage = () => {
    switch (value) {
      case 'ManageAttendees':
        return <ManageAttendees />
      case 'ManagePages':
        return <PageSettings reloadNavigation={ props.reloadNavigation }/>
      case 'ManageSettings':
        return <BalSettings />
      case 'CreateUser':
        return <CreateUser />
      case 'ImportAttendees':
        return <ImportAttendees />
      case 'Clear':
        return <Clear />
      default:
        return null;
    }
  }

  return (
    <>
      <button onClick={logOut}>Logga ut</button>
      <div className='adminNavigation'>
        <Tabs value={ value } onChange={ handleChange }  variant='scrollable'>
          <Tab label='Hantera anmälda' value='ManageAttendees' />
          <Tab label='Hantera balinställningar' value='ManageSettings' />
          <Tab label='Hantera sidor' value='ManagePages' />
          <Tab label='Importera amälda' value='ImportAttendees' />
          <Tab label='Skapa användare' value='CreateUser' />
          <Tab label='RENSA BAL' value='Clear' />
        </Tabs>
      </div>
      { getAdminPage() }
    </>
  );
}