import * as React from 'react';
import { getTokenFromLocalStorage } from '../../../Common';
import { toast } from 'react-hot-toast';

const Clear = () => {
  const clear = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/ClearBal`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        }
      }).then(response => response.json());
      if (response.status === 401) {
        toast.error("Du har inte rättigheter att göra detta");
      }
      else if (!response) {
        toast.error("Något gick fel balen skulle rensas");
        console.log('Something went wrong when clearing bal: ', response);
        return;
      }
      else {
        toast.success("Bal rensad");
      }
    }
    catch (err) {
      console.log('Something went wrong when clearing bal: ', err);
    }
    finally {
    }
  };


  return (
    <div>
      <h3>OBS DETTA RENSAR ALLA ANMÄLDA TOTALT. GÖR DETTA ENBART OM EN NY BAL SKA SKAPAS. SNÄLLA DET FINNS INGA BACKUPS GÖMDA. ÄR DU OSÄKER PÅ OM DETTA ÄR EN BRA KNAPP ATT KLICKA PÅ, FRÅGA MARTIN L!</h3>
      <button onClick={clear}>
        Rensa bal
      </button>
    </div>
  );
}

export default Clear;