import * as React from 'react';
import { useForm } from "react-hook-form";
import './index.css';
import { getTokenFromLocalStorage } from '../../../Common';
import { toast } from 'react-hot-toast';

export default function BalSettings() {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/GetBal`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        }
      });
      if (response.status === 401) {
        toast.error("Du har inte rättigheter att göra detta");
      }
      else if (!response.ok) {
        toast.error("Något gick fel när balinställningar skulle hämtas");
      }
      const jsonText = await response.text();
      const replacedText = jsonText.replace(/T00:00:00/g, '');
      return JSON.parse(replacedText);
    }
  });

  const onSubmit = async (data) => {
    const result = await postResponse(data);
    reset(result);
  }

  const postResponse = async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/UpdateBal`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        },
        body: JSON.stringify(data),
      });
      if (response.status === 401) {
        toast.error("Du har inte rättigheter att göra detta");
      }
      else if (!response.ok) {
        toast.error("Något gick fel när balinställningar skulle uppdateras");
      }
      else {
        toast.success("Balinställningar uppdaterade");
      }
      const jsonText = await response.text();
      const replacedText = jsonText.replace(/T00:00:00/g, '');
      return JSON.parse(replacedText);
    } catch (error) {
      console.error("Error:", error);
    }
  }


  return (
    <div className='BalSettings'>
      <p>Lämna fälten tomma för att ta bort valet från anmälan</p>
      <form onSubmit={ handleSubmit(onSubmit) }>
        <label htmlFor='balName'>Namn på balen</label>
        <br/>
        <input
          id='balName'
          { ...register('balName', { required: true }) }
          type='text'
        />
        <br/>
        <label htmlFor='balDate'>Baldag</label>
        <br/>
        <input
          id='balDate'
          { ...register('balDate', { required: true }) }
          type='date'
        />
        <br/>
        <label htmlFor='hardEndDate'>Dag när anmälan stängs helt</label>
        <br/>
        <input
          id='hardEndDate'
          { ...register('hardEndDate', { required: true }) }
          type='date'
        />
        <br/>
        <label htmlFor='reserveDate'>Dag när anmälan går över till reservlista</label>
        <br/>
        <input
          id='reserveDate'
          { ...register('reserveDate', { required: true }) }
          type='date'
        />
        <br/>
        <label htmlFor='startDate'>Dag när anmälan öppnar</label>
        <br/>
        <input
          id='startDate'
          { ...register('startDate', { required: true }) }
          type='date'
        />
        <br/>
        <label htmlFor='lastPaymentDate'>Sista betalningsdag</label>
        <br/>
        <input
          id='lastPaymentDate'
          { ...register('lastPaymentDate', { required: true }) }
          type='date'
        />
        <br/>
        <label htmlFor='reserveAttendees'>Antal tills sidan går till reservlista automatiskt</label>
        <br/>
        <input
          id='reserveAttendees'
          { ...register('reserveAttendees', { required: true }) }
          type='number'
        />
        <br/>
        <label htmlFor='noticeThreshold'>Hur långt från gränser skickas varningsmail?</label>
        <br/>
        <input
          id='noticeThreshold'
          { ...register('noticeThreshold', { required: true }) }
          type='number'
        />
        <br/>
        <label htmlFor='maxAttendees'>Maxantal balen</label>
        <br/>
        <input
          id='maxAttendees'
          { ...register('maxAttendees', { required: true }) }
          type='number'
        />
        <br/>
        <label htmlFor='maxAttendees'>Maxantal sexan</label>
        <br/>
        <input
          id='maxSexaAttendees'
          { ...register('maxSexaAttendees', { required: true }) }
          type='number'
        />
        <br/>
        <label htmlFor='student'>Pris för student</label>
        <br/>
        <input
          id='student'
          { ...register('student', { required: true }) }
          type='number'
        />
        <br/>
        <label htmlFor='nonStudent'>Pris för icke-student</label>
        <br/>
        <input
          id='nonStudent'
          { ...register('nonStudent', { required: true }) }
          type='number'
        />
        <br/>
        <label htmlFor='active'>Pris för förtoendevald</label>
        <br/>
        <input
          id='active'
          { ...register('active') }
          type='number'
        />
        <br/>
        <label htmlFor='giver'>Pris för gynnare</label>
        <br/>
        <input
          id='giver'
          { ...register('giver') }
          type='number'
        />
        <br/>
        <label htmlFor='sexa'>Pris för sexa</label>
        <br/>
        <input
          id='sexa'
          { ...register('sexa') }
          type='number'
        />
        <br/>
        <label htmlFor='medal'>Pris för medalj</label>
        <br/>
        <input
          id='medal'
          { ...register('medal') }
          type='number'
        />
        <br/>
        <label htmlFor='medalTrade'>Pris för inbyte av medalj</label>
        <br/>
        <input
          id='medalTrade'
          { ...register('medalTrade') }
          type='number'
        />
        <br/>
        <label htmlFor='book'>Pris för sångbok</label>
        <br/>
        <input
          id='book'
          { ...register('book') }
          type='number'
        />
        <br/>
        <label htmlFor='lunch'>Pris för ballunch</label>
        <br/>
        <input
          id='lunch'
          { ...register('lunch') }
          type='number'
        />
        <br/>
        <label htmlFor='snaps'>Pris för snapsbiljett</label>
        <br/>
        <input
          id='snaps'
          { ...register('snaps') }
          type='number'
        />
        <br/>
        <label htmlFor='alcoholFree'>Avdrag för alkoholfritt</label>
        <br/>
        <input
          id='alcoholFree'
          { ...register('alcoholFree') }
          type='number'
        />
        <br/>
        <label htmlFor='password'>Lösenord</label>
        <br/>
        <input
          id='password'
          { ...register('password') }
          type='text'
        />
        <br/>
        <label htmlFor='usePassword'>Använd lösenord</label>
        <br/>
        <input
          id='usePassword'
          { ...register('usePassword') }
          type='checkbox'
        />
        <br/>
        <label htmlFor='passwordDate'>Datum lösenord ej längre krävs</label>
        <br/>
        <input
          id='passwordDate'
          { ...register('passwordDate') }
          type='date'
        />
        <br/>
        <input type='submit' value='Uppdatera' />
      </form>
    </div>
  );
}